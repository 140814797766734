import { UserViewModel } from "@domains/auth/viewModels/UserViewModel";
import {
  ObjectValuesToStringUnion,
  ShareDataType,
} from "@domains/common/types/common";
import { ProjectInfoTrackingDataType } from "@domains/project/types/project";
import { UserNotificationInfo } from "@models/data-contracts";

const WEB_VIEW_HANDLER_NAMES = {
  // navigation
  NAVIGATE_BACK: "navigateBack",
  NAVIGATE_TO_POST: "navigateToPost",
  NAVIGATE_TO_EDIT_EVENT: "navigateToEditEvent",
  NAVIGATE_TO_EVENT_DETAIL: "navigateToEventDetail",
  NAVIGATE_TO_MAIN: "navigateToMain",
  NAVIGATE_TO_ENTRY: "navigateToEntry",
  NAVIGATE_TO_CHAT_LIST: "navigateToChatList",
  NAVIGATE_TO_CHAT_ROOM: "navigateToChatRoom",
  NAVIGATE_TO_MY_PAGE: "navigateToMyPage",
  NAVIGATE_TO_MY_PAGE_REVIEW: "navigateToMyPageReview",
  NAVIGATE_TO_MY_PAGE_EDIT_PROFILE: "navigateToMyPageEditProfile",
  NAVIGATE_TO_MY_PAGE_ACCOUNT: "navigateToMyPageAccount",
  NAVIGATE_TO_SETTING: "navigateToSetting",
  NAVIGATE_TO_MY_PAGE_WRITE_REVIEW: "navigateToMyPageWriteReview",
  NAVIGATE_TO_PROFILE_REVIEW: "navigateToProfileReview",
  NAVIGATE_TO_USER_PROFILE: "navigateToUserProfile",
  NAVIGATE_TO_EVENT_ATTENDANCE: "navigateToEventAttendance",
  NAVIGATE_TO_NOTIFICATION_LIST: "navigateToNotificationList",
  NAVIGATE_TO_PROJECT_DETAIL: "navigateToProjectDetail",

  // actions
  ACTION_LOGOUT: "actionLogout",
  ACTION_EDIT_PROFILE: "actionEditProfile",
  ACTION_SKIP_PROFILE_EDITING: "actionSkipProfileEditing",
  ACTION_DELETE_EVENT: "actionDeleteEvent",
  ACTION_SEND_EMAIL: "actionSendEmail",
  ACTION_HOST_GUEST_POLICY: "actionHostGuestPolicy",
  ACTION_PRIVACY_POLICY: "actionPrivacyPolicy",
  ACTION_OPEN_MAP: "actionOpenMap",
  ACTION_EVENT_JOIN: "actionEventJoin",
  ACTION_DELETE_ACCOUNT: "actionDeleteAccount",
  ACTION_CREATE_AND_UPDATE_POST: "actionCreateAndUpdatePost",
  ACTION_WRITE_REVIEW: "actionWriteReview",
  ACTION_OPEN_LINK_WEBVIEW: "actionOpenLinkWebView",
  ACTION_PERMISSION_REQUEST: "actionPermissionRequest",
  ACTION_NOTIFICATION_ROUTE: "actionNotificationRoute",
  ACTION_CHECK_APP_VERSION: "actionCheckAppVersion",
  ACTION_DOWNLOAD_IMAGES: "actionDownloadImages",
  ACTION_PAY_PROJECT: "actionPayProject",
  ACTION_SHARE_EVENT: "actionShareEvent",
  ACTION_CANCEL_PROJECT: "actionCancelProject",

  // set storage
  SET_STORAGE_REDIRECT_AS_PATH: "setStorageRedirectAsPath",
} as const;

export type HandlerNameType = ObjectValuesToStringUnion<
  typeof WEB_VIEW_HANDLER_NAMES
>;

class WebViewBridge {
  static instance: WebViewBridge;

  static getInstance() {
    if (!WebViewBridge.instance) {
      WebViewBridge.instance = new WebViewBridge();
    }
    return WebViewBridge.instance;
  }

  static isInAppWebView(): boolean {
    if (typeof window === "undefined") return false;

    return !!window.flutter_inappwebview;
  }

  // ------------------------------- handlers -------------------------------

  async callHandler(handlerName: HandlerNameType, data = {}) {
    if (window.flutter_inappwebview) {
      const result = await window.flutter_inappwebview.callHandler(
        handlerName,
        data,
      );
      return result;
    }
    console.error(
      "flutter_inappwebview is not available. Cannot call handler:",
      handlerName,
    );
  }

  // ------------------------------ navigation ------------------------------

  navigateToMain() {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.NAVIGATE_TO_MAIN);
  }

  navigateToEntry() {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.NAVIGATE_TO_ENTRY);
  }

  navigateBack() {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.NAVIGATE_BACK);
  }

  navigateToPost() {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.NAVIGATE_TO_POST);
  }

  navigateToEventDetail(eventId: number) {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.NAVIGATE_TO_EVENT_DETAIL, eventId);
  }

  navigateToChatList() {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.NAVIGATE_TO_CHAT_LIST);
  }

  navigateToChatRoom(roomId: number, eventId: number) {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.NAVIGATE_TO_CHAT_ROOM, {
      roomId,
      eventId,
    });
  }

  navigateToMyPage() {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.NAVIGATE_TO_MY_PAGE);
  }

  navigateToMyPageReview(reviewId?: string) {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.NAVIGATE_TO_MY_PAGE_REVIEW, {
      ...(reviewId && { reviewId }),
    });
  }

  navigateToSetting() {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.NAVIGATE_TO_SETTING);
  }

  navigateToMyPageEditProfile() {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.NAVIGATE_TO_MY_PAGE_EDIT_PROFILE);
  }

  navigateToMyPageAccount() {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.NAVIGATE_TO_MY_PAGE_ACCOUNT);
  }

  navigateToMyPageWriteReview(eventId: string, reviewId?: string) {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.NAVIGATE_TO_MY_PAGE_WRITE_REVIEW, {
      eventId,
      ...(reviewId && { reviewId }),
    });
  }

  navigateToProfileReview(hostId: string, reviewId?: string) {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.NAVIGATE_TO_PROFILE_REVIEW, {
      hostId,
      ...(reviewId && { reviewId }),
    });
  }

  navigateToEditEvent(eventId: string) {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.NAVIGATE_TO_EDIT_EVENT, eventId);
  }

  navigateToUserProfile(userId: number) {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.NAVIGATE_TO_USER_PROFILE, userId);
  }

  navigateToEventAttendance(eventId: string) {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.NAVIGATE_TO_EVENT_ATTENDANCE, {
      eventId,
    });
  }

  navigateToNotificationList() {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.NAVIGATE_TO_NOTIFICATION_LIST);
  }

  navigateToProjectDetail(projectId: number) {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.NAVIGATE_TO_PROJECT_DETAIL, {
      projectId,
    });
  }

  // -------------------------------- trigger --------------------------------

  triggerLogout() {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.ACTION_LOGOUT);
  }

  triggerEditProfile(redirectAsPath: string, isFirstProfileEditing = false) {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.ACTION_EDIT_PROFILE, {
      redirectAsPath,
      isFirstProfileEditing,
    });
  }

  triggerSendEmail() {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.ACTION_SEND_EMAIL);
  }

  triggerHostGuestPolicy() {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.ACTION_HOST_GUEST_POLICY);
  }

  triggerPrivacyPolicy() {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.ACTION_PRIVACY_POLICY);
  }

  triggerOpenMap(url: string) {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.ACTION_OPEN_MAP, { url });
  }

  triggerDeleteEvent() {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.ACTION_DELETE_EVENT);
  }

  triggerLegacyEventJoin() {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.ACTION_EVENT_JOIN);
  }

  async triggerEventJoin() {
    const result: "success" | "cancel" =
      await window.flutter_inappwebview.callHandler(
        WEB_VIEW_HANDLER_NAMES.ACTION_EVENT_JOIN,
      );

    return result;
  }

  triggerDeleteAccount() {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.ACTION_DELETE_ACCOUNT);
  }

  triggerCreateAndUpdatePost(eventId: number) {
    this.callHandler(
      WEB_VIEW_HANDLER_NAMES.ACTION_CREATE_AND_UPDATE_POST,
      eventId,
    );
  }

  triggerWriteReview(isEditingReview: boolean) {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.ACTION_WRITE_REVIEW, {
      isEditingReview,
    });
  }

  triggerSkipProfileEditing() {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.ACTION_SKIP_PROFILE_EDITING);
  }

  triggerOpenLinkWebView(url: string) {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.ACTION_OPEN_LINK_WEBVIEW, { url });
  }

  async checkPermit(type: "camera"): Promise<boolean> {
    const isGranted = await window.flutter_inappwebview.callHandler(
      WEB_VIEW_HANDLER_NAMES.ACTION_PERMISSION_REQUEST,
      { type },
    );

    return isGranted === "granted";
  }

  triggerNotificationRoute(notification: UserNotificationInfo["data"]) {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.ACTION_NOTIFICATION_ROUTE, {
      notification,
    });
  }

  async triggerCheckAppVersion(): Promise<string> {
    const appVersion: string = await this.callHandler(
      WEB_VIEW_HANDLER_NAMES.ACTION_CHECK_APP_VERSION,
    );
    return appVersion;
  }

  async triggerDownloadImages(imageUrls: string[]) {
    await this.callHandler(
      WEB_VIEW_HANDLER_NAMES.ACTION_DOWNLOAD_IMAGES,
      imageUrls,
    );
  }

  async triggerPayProject(
    projectInfo: ProjectInfoTrackingDataType,
    hasProfile?: UserViewModel["hasProfile"],
  ) {
    const result: "success" | "failed" = await this.callHandler(
      WEB_VIEW_HANDLER_NAMES.ACTION_PAY_PROJECT,
      { ...projectInfo, hasProfile },
    );

    return result;
  }

  async triggerCancelProject(
    projectInfo: ProjectInfoTrackingDataType,
    hasProfile?: UserViewModel["hasProfile"],
  ) {
    const result: "success" | "failed" = await this.callHandler(
      WEB_VIEW_HANDLER_NAMES.ACTION_CANCEL_PROJECT,
      { ...projectInfo, hasProfile },
    );

    return result;
  }

  triggerShareEvent(shareData: ShareDataType) {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.ACTION_SHARE_EVENT, { shareData });
  }

  // ------------------------------ set storage ------------------------------

  setStorageRedirectAsPath(redirectAsPath: string) {
    this.callHandler(WEB_VIEW_HANDLER_NAMES.SET_STORAGE_REDIRECT_AS_PATH, {
      redirectAsPath,
    });
  }

  // -------------------------------------------------------------------------
}

export default WebViewBridge;
